<template>
  <div class="mainpage">
    <div class="head">
      <div class="head-title">编写实验报告</div>
      <div class="retutnbt" @click="toback">返回</div>
    </div>
    <div class="line"></div>
    <div class="maincontain">
      <el-form class="elform" ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item class="elinput" label="标题:" prop="title">
          <el-input :disabled="type == '编辑'" v-model="form.title" placeholder="请输入(20字以内)"></el-input>
        </el-form-item>
        <el-form-item class="elinput" label="模块选择:" prop="vr_module_name">
          <el-select v-model="form.vr_module_id" clearable placeholder="请选择">
            <el-option
              v-for="item in moduleoption"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="elinput2" label="实验报告:" prop="content">
          <div class="text-editor">
            <Tinymce
              ref="editor"
              v-model="form.content"
              style="height: auto; border-radius: 22px"
              @setInput="getValue"
            ></Tinymce>
          </div>
        </el-form-item>

        <el-form-item class="elinput2" label="附件">
          <UploadFiles
            :files="fileList"
            :module="'practice_report,url'"
            cloudType="'PracticeReport/url'"
            @setUrl="getUrl"
          />
        </el-form-item>
      </el-form>
      <div class="bts">
        <div class="bt" @click="toback()">取消</div>
        <div class="bt bt1" @click="submitbt('form',0)">保存</div>
        <div class="bt bt1" @click="submitbt('form',1)">提交</div>
      </div>
    </div>
  </div>
</template>

<script>
// import Customdialog from "@/components/customdialog.vue";
import UploadFiles from '@/components/upload-files'
import Tinymce from "@/components/VueTinymce/index.vue"

import { getvrmodulelist } from "@/api/vrmodule"
import {
  addvrstudyreport,
  getvrstudyreportinfo,
  editvrstudyreport,
} from "@/api/vrstudyreport"

export default {
  name: "mainpage",
  components: {
    // Customdialog,
    Tinymce,
    UploadFiles
  },
  data () {
    return {
      form: {
        title: "",
        vr_module_id: "",
        content: "",
        action: "",
        file: '',
        vr_study_id: ''
      },
      moduleoption: [],
      fileList: [],
      vr_id: "",
      type: "",
      itemid: "",
      rules: {
        title: [
          { required: true, message: '请输入报告名称', trigger: 'blur' },
        ],
        // vr_module_name: [
        //   { required: true, message: '请选择实验模块', trigger: 'change' },
        // ],
        content: [
          { required: true, message: '请填写实验报告', trigger: 'blur' },
        ],

      },
      setFile: [],
    }
  },
  created () {
    this.vr_id = this.$route.query.vr_id
    this.form.vr_study_id = this.$route.query.vr_study_id
    this.itemid = this.$route.query.itemid
    this.type = this.$route.query.type
    if (this.type == 2) {
      this.getvrstudyreportinfo({
        id: this.$route.query.itemid,
      })
    }

    this.getvrmodulelist({
      vr_id: this.$route.query.vr_id,
    })
  },
  methods: {
    toback () {
      this.$router.push({
        path: "/home/personalcenter/mainpage?id=0&componentId=Virtualsimulationexperiment",
        // query: {
        //   id:0,
        //   componentId:'Virtualsimulationexperiment'
        // },
      })
    },

    submitbt (form, action) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.form.action = action
          if (this.type == 1) {
            this.addvrstudyreport(this.form)
          } else if (this.type == 2) {
            this.form.id = parseInt(this.itemid)
            this.editvrstudyreport(this.form)
          }
        } else {
          return false
        }
      })
    },
    getvrstudyreportinfo (params) {
      getvrstudyreportinfo(params)
        .then((response) => {
          if (response.data) {
            this.form.title = response.data.title
            this.form.vr_module_id = response.data.vr_module_id
            this.form.content = response.data.content
            this.fileList = response.data.file
          }
        })
        .catch((error) => {
          //console.log(error);
        })
    },
    getvrmodulelist (params) {
      getvrmodulelist(params)
        .then((response) => {
          this.moduleoption = response.data
        })
        .catch((error) => {
          //console.log(error);
        })
    },
    addvrstudyreport (params) {
      addvrstudyreport(params)
        .then((response) => {
          if (response.code === 0) {
            this.$message({
              type: 'success',
              message: '实验报告提交成功'
            })
            this.$router.push({
              path: "/home/personalcenter/mainpage?id=0&componentId=Virtualsimulationexperiment",
              query: {
                vr_id: this.vr_id,
                vr_study_id: this.form.vr_study_id
              },
            })
          }
        })
        .catch((error) => {
          //console.log(error);
        })
    },
    editvrstudyreport (params) {
      editvrstudyreport(params)
        .then((response) => {
          if (response.code === 0) {
            this.$message({
              type: 'success',
              message: '实验报告提交成功'
            })
            this.$router.push({
              path: "/home/personalcenter/mainpage?id=0&componentId=Virtualsimulationexperiment",
              query: {
                vr_id: this.vr_id,
                vr_study_id: this.form.vr_study_id
              },
            })
          }
        })
        .catch((error) => {
          //console.log(error);
        })
    },
    // 获取富文本得值
    getValue (data) {
      this.form.content = data
    },
    // 获取文件上传的返回值
    getUrl (data, key) {
      this.form.file = data
    }
  },
};
</script>
<style scoped lang="scss">
.mainpage {
  min-height: calc(100vh - 240px);
  background: #ffffff;
  .head {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    padding-top: 10px;
    height: 40px;
    .head-title {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #3d84ff;
      user-select: none;
      white-space: nowrap;
    }
    .retutnbt {
      margin-right: 40px;
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #3d84ff;
      border-radius: 4px;
      color: white;
      user-select: none;
      cursor: pointer;
    }
  }
  .line {
    margin-top: 10px;
    margin-bottom: 4px;
    border-bottom: 2px solid #cccccc;
  }
  .maincontain {
    background: #ffffff;
    border-radius: 4px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    .elform {
      .elinput {
        width: 500px;
      }
      .elinput2 {
        width: 800px;
      }
      .inputcontain {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .bt {
          padding: 0px;
          margin: 0px;
          margin-left: 10px;
          width: 64px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #cccccc;
          border-radius: 4px;
          font-size: 18px;

          font-weight: 400;
          color: #a0a0a0;
          user-select: none;
          cursor: pointer;
        }
        .bt1 {
          background: #3d84ff;
          color: #ffffff;
        }
      }
    }
    .bts {
      margin-top: 40px;
      margin-right: 100px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .bt {
        margin-right: 10px;
        width: 64px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #cccccc;
        border-radius: 4px;
        font-size: 18px;

        font-weight: 400;
        color: #a0a0a0;
        user-select: none;
        cursor: pointer;
      }
      .bt1 {
        background: #3d84ff;
        color: #ffffff;
        border: 1px solid #3d84ff;
      }
    }
  }
}
</style>
